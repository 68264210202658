//dependencias
import React from "react";
import { Route, Switch } from "react-router-dom";

//componentes
import Grados from "./components/grados";
import Permisos from "./components/permisos";
import ConsultarNotas from "./components/consultarNotas";
import ReporteP from "./components/reportePlanificacion";
import SubirCurriculum from "./components/subirCurriculum";
import CxC from "./components/cpc";
import Pago from "./components/reportarPago/";
import Inicio from "./components/Inicio";
import Login from "./components/Login";
import Pagina404 from "./components/Pagina404";
import Chat from "./components/Chat";
import PrimerTrimestre from "./components/primerT";
import SegundoTrimestre from "./components/segundoT";
import TercerTrimestre from "./components/tercerT";
import PDF from "./components/pdf";

const AppRoutes = () => (
  <Switch>
    {/* <Route exact path="/reportePlaniMaestro" component={ReportePM} /> */}
    <Route exact path="/pdf" component={PDF} />
    <Route exact path="/primerT" component={PrimerTrimestre} />
    <Route exact path="/segundoT" component={SegundoTrimestre} />
    <Route exact path="/tercerT" component={TercerTrimestre} />
    <Route exact path="/grados" component={Grados} />
    <Route exact path="/permiso" component={Permisos} />
    <Route exact path="/consultarNotas" component={ConsultarNotas} />
    <Route exact path="/reporteP" component={ReporteP} />
    <Route exact path="/subirCurri" component={SubirCurriculum} />
    <Route exact strict path="/cxc" component={CxC} />
    <Route exact strict path="/pago" component={Pago} />
    <Route exact strict path="/login" component={Login} />
    <Route exact strict path="/" component={Inicio} />
    <Route exact strict path="/chat" component={Chat} />
    <Route component={Pagina404} />
  </Switch>
);
export default AppRoutes;
