import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { Link } from "react-router-dom";

//componentes
import "./reportePago.css";
import Alert from "../../modal/alert";

class ReportePago extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identificacionp1: "",
      identificacionp2: "",
      tokenVerificar: "",
      tokenVerificarOn: false,
      token: "",
      tokenOn: false,
      opnivel: "MATERNAL",
      acudiente: "",
      alumno: "",
      referencia: "",
      banco: "",
      banco2: "",
      oppago: "TRANSFERENCIA",
      nombreper: "",
      fecha: "",
      monto: "",
      btnEnviarOn: false,
      btnReportarOn: false,
      meses: "",
      estatusOn: false,
      ifechamedi: [],
      cxcRe: [],
      listaOn: false,
      mensajealerta: false,
      correo: "",
      montoAplicado: "",
    };
  }

  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
        tokenOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange3(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.calcularEdad(e.target.value);
  }

  handleClose = () => {
    this.setState({ mensajealerta: false, tokenOn: false });
  };

  limpiar = () => {
    this.setState({
      identificacionp1: "",
      identificacionp2: "",
      tokenVerificar: "",
      tokenVerificarOn: false,
      token: "",
      tokenOn: false,
      opnivel: "MATERNAL",
      acudiente: "",
      alumno: "",
      referencia: "",
      banco: "",
      oppago: "TRANSFERENCIA",
      nombreper: "",
      fecha: "",
      monto: "",
      btnEnviarOn: false,
      btnReportarOn: false,
      meses: "",
      estatusOn: false,
      ifechamedi: [],
      listaOn: false,
      mensajealerta: false,
      montoAplicado: "",
    });
  };

  verificartoken = () => {
    if (this.state.tokenVerificar.length > 0) {
      if (this.state.tokenVerificar === this.state.token) {
        console.log("Token verificado positivo");
        this.setState({
          token: "",
          tokenVerificarOn: true,
        });
      } else {
        console.log("Token verificado negativo");
      }
    }
  };

  cerrarLista2 = () => {
    if (this.state.btnReportarOn) {
      this.setState({ btnReportarOn: false });
    } else {
      this.setState({ btnReportarOn: true });
    }
  };

  aplicarPagoCxC = () => {
    if (
      this.state.referencia.length > 0 &&
      this.state.banco.length > 0 &&
      this.state.nombreper.length > 0 &&
      this.state.fecha.length > 0 &&
      this.state.monto.length > 0
    ) {
      let data = new FormData();
      data.append("cedulaEstudiante", this.state.identificacionp2);
      data.append("monto", this.state.monto);
      data.append("boton", "aplicarPagoCxC");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportarPago2.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xialumno = res.data.variable2;
            this.setState({
              cxcRe: xialumno,
              montoAplicado: res.data.variable4,
              btnReportarOn: true,
            });
            let montodemas = 0;
            montodemas = res.data.variable3;
            if (montodemas > 0) {
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Reporte de pago",
                cuerpoM:
                  "El monto que indico es mayor a la deuda existente, en caso de aceptar, la diferencia será aplicada a la siguiente Mensualidad.",
              });
            }
          } else {
            this.setState({
              btnReportarOn: false,
            });
            let montodemas = 0;
            montodemas = res.data.variable3;
            if (montodemas > 0) {
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Reporte de pago",
                cuerpoM:
                  "El Alumno no tiene deuda pendiente, en caso de aceptar, la diferencia será aplicada a la siguiente Mensualidad.",
              });
            }
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Reporte de pago",
        cuerpoM: "Debe llenar todos los campos del formulario Gracias...",
      });
    }
  };

  registrarPago = () => {
    if (
      this.state.referencia.length > 0 &&
      this.state.banco.length > 0 &&
      this.state.nombreper.length > 0 &&
      this.state.fecha.length > 0 &&
      this.state.monto.length > 0
    ) {
      let data = new FormData();

      data.append("cedulaAcudiente", this.state.identificacionp1);
      data.append("acudiente", this.state.acudiente);
      data.append("cedulaEstudiante", this.state.identificacionp2);
      data.append("estudiante", this.state.alumno);
      data.append("formaPago", this.state.oppago);
      data.append("referencia", this.state.referencia);
      data.append("banco", this.state.banco);
      data.append("banco2", this.state.banco2);
      data.append("nombreper", this.state.nombreper);
      data.append("fecha", this.state.fecha);
      data.append("monto", this.state.monto);
      data.append("correo", this.state.correo);
      data.append("arregloPago", JSON.stringify(this.state.cxcRe));
      data.append("boton", "registrarPago");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportarPago.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.cerrarLista2();
            this.setState({
              btnEnviarOn: true,
            });
          } else {
            this.setState({
              btnEnviarOn: false,
            });
          }
        });
    }
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("cedulaRepresentante", this.state.identificacionp1);
      data.append("cedulaEstudiante", e);
      data.append("boton", "buscarCedula");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportarPago.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.setState({
              correo: res.data.variable2,
              acudiente: res.data.variable3,
              alumno: res.data.variable4,
              identificacionp2: e,
              listaOn: false,
              tokenVerificarOn: true,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Reporte de pago",
              cuerpoM:
                "Existe un problema, debe ponerse en contacto con la institucion gracias...",
              listaOn: false,
            });
          }
        });
    }
  };

  buscarCedula = () => {
    if (this.state.identificacionp1.length > 0) {
      //console.log('cedula '+this.state.identificacionp1)
      let data = new FormData();
      data.append("cedulaRepresentante", this.state.identificacionp1);
      data.append("boton", "buscarCedulaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportarPago.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data);
          if (res.data.variable1) {
            let xifechamedi = res.data.variable2;
            //console.log(res.data)
            this.setState({
              tokenOn: true,
              ifechamedi: xifechamedi,
              listaOn: true,
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Reporte de pago",
              cuerpoM: res.data.variable2,
            });
          }
        });
    }
  };

  render() {
    //console.log(this.state.token)
    return (
      <div className="principal-pago row">
        <div className="contenedor-pago col-12">
          <div className="titulo-pago ">
            <p>MENU PARA REGISTRAR PAGOS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {!this.state.tokenVerificarOn && (
            <div className="contenedor-titulo row">
              <div className="titulo-pago col-12">
                <p>DEBE INGRESAR LA CEDULA O PASAPORTE DEL REPRESENTANTE. </p>
              </div>
              <div className="contenedor-cedula row">
                <label className="labelcedulac">
                  Inserte Cedula/Pasaporte Representante.
                </label>
                <div className="contenedorcedula1 col-4">
                  <input
                    className="form-control"
                    type="text"
                    name="identificacionp1"
                    id="identificacionp1"
                    autoComplete="off"
                    placeholder="Identificacion Representante."
                    maxLength="30"
                    readOnly={this.state.tokenOn ?? "readonly"}
                    required
                    onChange={this.onChange.bind(this)}
                    value={this.state.identificacionp1.toUpperCase()}
                  />
                </div>
              </div>
              {!this.state.tokenOn && (
                <div className="contenedorcedula1 col-12">
                  <button
                    className="boton"
                    onClick={this.buscarCedula.bind(this)}
                  >
                    Consultar
                  </button>
                </div>
              )}
            </div>
          )}

          {this.state.listaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-7">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">Listado de Alumnos</span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.cerrar.bind(this)}
                  >
                    X
                  </span>
                </div>
                <span className="titulo-casos">Cedula</span>
                <span className="titulo-casos">Nombre Alumno</span>
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.ifechamedi.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                width="20"
                                onClick={this.consulta.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable1}
                              </td>
                              <td
                                width="20"
                                onClick={this.consulta.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable2}
                              </td>{" "}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.btnReportarOn === true && (
            <div className="flotador4xf">
              <div className="flotador1">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Listado de Deudas por Cobrar a las que se le Aplicará el
                    Pago
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.cerrarLista2.bind(this)}
                  >
                    X
                  </span>
                </div>
                <div className="cont-listmedi row">
                  <div className="listcxc col-12">
                    <table className="tablacxc" border="1">
                      <thead>
                        <tr>
                          <th className="titucxc1">FECHA</th>
                          <th className="titucxc2">DESCRIPCION</th>
                          <th className="titucxc1">MONTO DEUDA</th>
                          <th className="titucxc1">MONTO A PAGAR</th>
                        </tr>
                      </thead>
                      {this.state.cxcRe.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : "odd"}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td className="totalescxc" width="10">
                              {item.variable2}
                            </td>
                            <td className="totalescxc" width="10">
                              {item.variable3}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable4}
                            </td>
                            <td className="renglon" width="10">
                              {item.variable5}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                  <div className="row">
                    <div className="cont-titulomonto col-12">
                      <div>
                        <button
                          className="boton"
                          id="boton1"
                          onClick={this.registrarPago.bind(this)}
                        >
                          Aplicar Pago
                        </button>
                        <button
                          className="boton"
                          id="boton2"
                          onClick={this.cerrarLista2.bind(this)}
                        >
                          No Aplicar Pago
                        </button>
                        <div className="titulomonto">
                          Monto Aplicado {this.state.montoAplicado}$
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="titulo-pago col-12">
                <p>
                  EL PAGO AFECTARA EL ORDEN SEGUN SE VISUALIZA EN LA TABLA, EN
                  CASO DE QUERER AFECTAR OTROS ITEMS Y NO LOS INDICADOS DEBERÁ
                  DIRIGIRSE A LA ADMINISTRACIÓN DE LA INSTITUCIÓN.
                </p>
              </div>
            </div>
          )}

          {this.state.tokenVerificarOn && !this.state.btnEnviarOn && (
            <div className="contenedor-titulo row">
              <div className="titulo-pago col-12">
                <p>AHORA PUEDE REGISTRAR EL PAGO. </p>
              </div>
              <div className="contenedor-cedula col-6">
                <label className="labelcedulac">Acudiente.</label>

                <input
                  className="form-control"
                  type="text"
                  name="ide"
                  id="acudiente"
                  autoComplete="off"
                  readOnly="readonly"
                  onChange={this.onChange.bind(this)}
                  value={this.state.acudiente}
                />
              </div>
              <div className="contenedor-cedula col-6">
                <label className="labelcedulac">Estudiante.</label>
                <input
                  className="form-control"
                  type="text"
                  name="alumno"
                  id="alumno"
                  autoComplete="off"
                  readOnly="readonly"
                  onChange={this.onChange.bind(this)}
                  value={this.state.alumno}
                />
              </div>

              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">Banco Emisor.</label>

                <input
                  className="form-control"
                  type="text"
                  name="banco"
                  id="banco"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.banco.toUpperCase()}
                />
              </div>
              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">Referencia.</label>
                <input
                  className="form-control"
                  type="text"
                  name="referencia"
                  id="referencia"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.referencia.toUpperCase()}
                />
              </div>
              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">Banco Receptor.
                <select
                      id="banco2"
                      name="banco2"
                      value={this.state.banco2}
                      onChange={this.onChange.bind(this)} 
                    >
                      <option>-- Seleccionar banco --</option>
                      <option value="1">BANCO BAC</option>
                      <option value="2">BANCO BANESCO</option>
                      <option value="3">BANCO GENERAL</option>
                    </select>
                    </label>
                {/* <input
                  className="form-control"
                  type="text"
                  name="banco2"
                  id="banco2"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.banco2.toUpperCase()}
                /> */}
              </div>
              <div className="contenedor-cedula col-2">
                <label className="labelcedulac">Monto a Pagar.</label>

                <input
                  className="form-control"
                  type="number"
                  name="monto"
                  id="monto"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.monto}
                />
              </div>
              <div className="contenedor-cedula col-3">
                <label className="labelcedulac">Fecha Transacción.</label>
                <input
                  className="form-control"
                  type="date"
                  name="fecha"
                  id="fecha"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.fecha}
                />
              </div>
              <div className="contenedor-cedula col-4">
                <label className="labelcedulac">
                  Nombre de la persona que realizó la Transferencia.
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="nombreper"
                  id="nombreper"
                  autoComplete="off"
                  onChange={this.onChange.bind(this)}
                  value={this.state.nombreper.toUpperCase()}
                />
              </div>
              <div className="cont-radio col-2">
                <label className="labelcedulac1">Forma de Pago.</label>
                <label className="transfe">
                  <input
                    className="opnivel1"
                    type="radio"
                    value="TRANSFERENCIA"
                    name="oppago"
                    checked={this.state.oppago === "TRANSFERENCIA"}
                    onChange={this.onChange.bind(this)}
                  />
                  Transferencia.
                </label>
                <label>
                  <input
                    className="opnivel2"
                    type="radio"
                    value="DEPOSITO"
                    name="oppago"
                    checked={this.state.oppago === "DEPOSITO"}
                    onChange={this.onChange.bind(this)}
                  />
                  Deposito.
                </label>
              </div>
              {!this.state.btnEnviarOn && (
                <div className="contenedorcedula1 col-12">
                  {/* <button className="boton" onClick={this.registrarPago.bind(this)}>Enviar Pago</button> */}
                  <button
                    className="boton"
                    onClick={this.aplicarPagoCxC.bind(this)}
                  >
                    Enviar Pago
                  </button>
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              )}
            </div>
          )}
          {this.state.btnEnviarOn && (
            <div className="titulo-pago col-12">
              <p>
                EL PROCESO SE COMPLETO DE FORMA EXITOSA, LA INSTITUCIÓN LE
                ENVIARÁ EL RECIBO A SU CORREO, GRACIAS.{" "}
              </p>
              <button className="boton" onClick={this.limpiar.bind(this)}>
                Continuar
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default ReportePago;
