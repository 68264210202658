import React, { Component } from "react";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./consultarNotas.css";
import Alert from "../../modal/alert";
import TareasRecibidas from "../notasRecibidas/notasRecibidas";

class SubirTareas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idmateria: "",
      tidmateria: "",
      idtarea: "",
      idprofesor: "",
      idgrado: "",
      trimestre: "",
      cedulaAlumno: "",
      actividad: "",
      enviarOn: false,
      adjuntarOn: false,
      datosAdjuntarOn: false,
      datosRecibirOn: false,
      tareasEnviadasOn: false,
      recibirOn: false,
      imaterias: [],
      icedulaH: [],
      itarea: [],
      itareasRecibidas: [],
      mensajealerta: false,
      loading: false,
      datosAdjuntadosOn: false,
    };
  }
  limpiar = () => {
    this.setState({
      idmateria: "",
      tidmateria: "",
      idtarea: "",
      idprofesor: "",
      idgrado: "",
      cedulaAlumno: "",
      trimestre: "",
      actividad: "",
      enviarOn: false,
      adjuntarOn: false,
      mensajealerta: false,
      loading: false,
      tareasEnviadasOn: false,
      datosAdjuntadosOn: false,
      recibirOn: false,
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2 = (e) => {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "cedulaAlumno") this.consultarMaterias(e.target.value);
    if (index === "idtarea" && e.target.value.length > 0) {
      this.setState({ enviarOn: true, adjuntarOn: false });
    }
    if (index === "trimestre") {
      this.setState({ idmateria:''});
    }
  };

  onChange3 = (e) => {
    let idplane = e.target.value;
    console.log(idplane);
    console.log(this.state.imaterias);
    let itemfind = this.state.imaterias.find(
      (e) => e.variable1 === parseInt(idplane)
    );
    console.log(itemfind);

    let index =
      typeof itemfind !== "undefined"
        ? this.state.imaterias.indexOf(itemfind)
        : null;

    let xidmateria = this.state.imaterias[index].variable2;
    let xidprofesor = this.state.imaterias[index].variable4;
    let xidgrado = this.state.imaterias[index].variable6;

    this.setState({
      [e.target.name]: e.target.value,
      idprofesor: xidprofesor,
      idgrado: xidgrado,
      enviarOn: false,
      adjuntarOn: false,
    });

    this.consultarActividades(xidprofesor, xidgrado, xidmateria);
  };

  onChange4 = (e) => {
    let idplane = e.target.value;
    //console.log(idplane);
    //console.log(this.state.imaterias);
    let itemfind = this.state.imaterias.find(
      (e) => e.variable1 === parseInt(idplane)
    );
    //console.log(itemfind);

    let index =
      typeof itemfind !== "undefined"
        ? this.state.imaterias.indexOf(itemfind)
        : null;

    let xidmateria = this.state.imaterias[index].variable2;
    let xidprofesor = this.state.imaterias[index].variable4;
    let xidgrado = this.state.imaterias[index].variable6;
    //this.consultarActividades2(xidprofesor, xidgrado, xidmateria);
    this.setState({
      [e.target.name]: e.target.value,
      tidmateria: xidmateria,
      idprofesor: xidprofesor,
      idgrado: xidgrado,
      enviarOn: false,
      adjuntarOn: false,
      recibirOn: true,
    });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  adjuntar = (e) => {
    if (this.state.adjuntarOn) {
      this.setState({ adjuntarOn: false });
    } else {
      this.setState({ adjuntarOn: true, enviarOn: false });
    }
  };

  consultarMaterias = (e) => {
    let data = new FormData();
    data.append("cedulaAlumno", e);
    data.append("boton", "buscarMaterias");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/subirTareas.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data)
        let ximaterias = res.data.variable2;
        this.setState({
          imaterias: ximaterias,
        });
      });
  };

  buscarAlumno = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarAlumnos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumno = res.data.variable2;
      this.setState({
        icedulaH: xialumno,
      });
    });
  };

  // buscarAlumno = () => {
  //   let form = new FormData();
  //   form.append("clave", this.props.usuario1.password);
  //   form.append("boton", "buscarAlumno");
  //   let config = {
  //     headers: { "Content-Type": "text/html" },
  //   };
  //   const xurl = "https://institutobilinguelasnaciones.com/backend/chat.php";
  //   axios.post(xurl, form, config).then((res) => {
  //     if (res.data.variable1) {
  //       this.setState({
  //         cedulaAlumno: res.data.variable1,
  //       });
  //       this.consultarMaterias(res.data.variable1);
  //     }
  //   });
  // };

  consultarActividades3(a, b) {
    let data = new FormData();
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("idactividad", a);
    data.append("actividad", b);
    data.append("idmateria", this.state.idmateria);
    data.append("boton", "buscarActividadesEnviadas");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        itareasRecibidas: xiactividades,
        recibirOn: true,
      });
    });
  }

  consultarActividades2(a, b, c, d) {
    let data = new FormData();
    data.append("idprofesor", a);
    data.append("idgrado", b);
    data.append("idmateria", c);
    data.append("anoEscolar", moment().format("YYYY"));
    data.append("boton", "buscarActividades3");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        itarea: xiactividades,
      });
    });
  }

  consultarActividades(a, b, c, d) {
    let data = new FormData();
    data.append("idprofesor", a);
    data.append("idgrado", b);
    data.append("idmateria", c);
    data.append("anoEscolar", moment().format("YYYY"));
    data.append("boton", "buscarActividades2");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
      data: data,
    }).then((res) => {
      let xiactividades = res.data.variable2;
      this.setState({
        itarea: xiactividades,
      });
    });
  }

  estadoEnviarTarea = () => {
    if (this.state.datosAdjuntarOn) {
      this.setState({ datosAdjuntarOn: false });
    } else {
      this.setState({ datosAdjuntarOn: true, datosRecibirOn: false });
      this.limpiar();
    }
  };

  estadoRecibirTarea = () => {
    if (this.state.datosRecibirOn) {
      this.setState({ datosRecibirOn: false });
    } else {
      this.setState({ datosRecibirOn: true, datosAdjuntarOn: false });
      this.limpiar();
    }
  };

  a = () => {};
  componentDidMount() {
    // this.buscarAnioEscolar();
    // this.cargarFechas();
    // this.buscarProfesor();
    this.buscarAlumno();
  }
  render() {
    return (
      <div className="cont-servi-principalx row">
        <div className="eslogan1su1 col-12">
          <div className="titulo-cxc ">
            <p>CONSULTA DE EVALUACIONES REALIZADAS. </p>
          </div>

          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="cedulaAlumno"
                      name="cedulaAlumno"
                      value={this.state.cedulaAlumno}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccione el Alumno --</option>
                      {this.state.icedulaH.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="idmateria"
                      name="idmateria"
                      value={this.state.idmateria}
                      onChange={this.onChange4.bind(this)}
                    >
                      <option>-- Materia --</option>
                      {this.state.imaterias.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable3 + " - " + item.variable5}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {this.state.recibirOn && (
                    <div className="row">
                      <TareasRecibidas
                        open={this.state.recibirOn}
                        cedulaAlumno={this.state.cedulaAlumno}
                        idmateria={this.state.tidmateria}
                        trimestre={this.state.trimestre}
                        onClose={() => this.setState({ recibirOn: false })}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="contenedorcedula1f col-4">
                <div className="acciones">
                  <Link to="/">
                    <button className="boton">Salir</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* {this.state.adjuntarOn && (
            <div className="row">
              <AdjuntaTareas
                cedulaAlumno={this.state.cedulaAlumno}
                idactividad={this.state.idtarea}
              />
            </div>
          )} */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps)(SubirTareas);
