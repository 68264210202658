import axios from "axios";
const config = {
  headers: { "Content-Type": "text/html" },
};


// Buscar si hay mas de un hijo como Alumno

export const BuscarCantidadAlumno = async (password) => {
  let form = new FormData();
  form.append("clave", password);
  form.append("boton", "buscarCantidadAlumno");

  const xurl = "https://institutobilinguelasnaciones.com/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Busca los datos del Alumno

export const BuscarAlumno = async (password) => {
  let form = new FormData();
  form.append("clave", password);
  form.append("boton", "buscarAlumno");

  const xurl = "https://institutobilinguelasnaciones.com/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Busca los alumnos relacionados al profesor 

export const BuscarMateria = async (alumno, val) => {
  let form = new FormData();
  form.append("cedulaAlumno", alumno);
  form.append("boton", "materiasChat");
  form.append("nombreMateria", val);
  let xurl = "https://institutobilinguelasnaciones.com/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Busca los chats de un profesor

export const buscarChatEnviar = async (alumno) => { 
  console.log(alumno)
  let form = new FormData();
  form.append("cedulaAlumno", alumno);
  form.append("boton", "buscarChatEnviarAlumno");
  let xurl = "https://institutobilinguelasnaciones.com/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Busca los mensajes de un chat

export const BuscarMensajesChat = async (id_sala) => {
  let form = new FormData();
  form.append("idsala", id_sala);
  form.append("boton", "buscarChatMensaje");
  let xurl = "https://institutobilinguelasnaciones.com/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};
// Marca sala como leido y los mensajes de un chat

export const MarcarSalaLeido = async (id_sala) => {
  let form = new FormData();
  form.append("idsala", id_sala);
  form.append("boton", "cambiarEstadoMensajeAlumno");
  let xurl = "https://institutobilinguelasnaciones.com/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};

// Crea los mensajes de un chat o El en si no existe

export const CrearMensajesChat = async (
  idprofesor,
  cedulaAlumno,
  idmateria,
  mensaje
) => {
  let form = new FormData();
  form.append("idprofesor", idprofesor);
  form.append("cedulaAlumno", cedulaAlumno);
  form.append("idmateria", idmateria);
  form.append("mensaje", mensaje);
  form.append("boton", "guardarChatEnviadoAlumnos");
  let xurl = "https://institutobilinguelasnaciones.com/backend/chat.php";
  let response = await axios.post(xurl, form, config);
  return response;
};
