import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import renderHTML from "react-render-html";
//componentes
import "./reporteP.css";
import Modal from "../modal2/modal";
import Alert from "../../modal/alert";

class ReportePla extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fecha: "",
      ifechamedi: [],
      dia: [],
      mes: [],
      anio: [],
      dateArray: [],
      mensajealerta: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: "",
      idcurso: "",
      idfechaPlani: "",
      fechaImprimir: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      cedulaHijo: "",
      verplanillaOn: false,
      mostrarOn: false,
      nalumno: [],
      icedulaH: [],
      ianoEscolar: [],
      icursos: [],
      ifechaPlani: [],
      someHTML: [],
      someHTML2: [],
      iprofesor: [],
      pasoOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      fecha: "",
      ifechamedi: [],
      mensajealerta: false,
      mostrarOn: false,
      tbusqueda: "",
      codigo3: "",
      codigo4: "",
      anoEscolar: "",
      idcurso: "",
      idfechaPlani: "",
      fecha1: "",
      fecha2: "",
      tipoR: "",
      idprofesor: "",
      cedulaHijo: "",
      someHTML: [],
      someHTML2: [],
      verplanillaOn: false,
      nalumno: [],
      dateArray: [],
      pasoOn: false,
    });
  };
  cerrar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  paso = () => {
    //console.log(this.state.listaOn)
    if (this.state.pasoOn) {
      this.setState({
        pasoOn: false,
      });
    }
    if (!this.state.pasoOn) {
      this.setState({
        pasoOn: true,
      });
    }
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "anoEscolar") this.buscarCurso(e.target.value);
    if (index === "idcurso") this.buscarFP(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
      someHTML: [],
      someHTML2: [],
    });
  }
  onChange4(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
      someHTML: [],
      someHTML2: [],
      fecha1: "",
      fecha2: "",
    });
  }
  buscarFP = (e) => {
    if (e.length > 0) {
      let form = new FormData();
      form.append("idgrado", e);
      form.append("boton", "buscarFP");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios.post(xurl, form, config).then((res) => {
        let xifechaPlani = res.data.variable2;
        this.setState({
          ifechaPlani: xifechaPlani,
          idfechaPlani: "",
          someHTML: [],
          someHTML2: [],
          fecha1: "",
          fecha2: "",
        });
      });
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM: "Debe Seleccionar un Grado, gracias.",
      });
    }
  };
  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarCurso = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("boton", "buscarCurso2");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/planificacion.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let xicursos = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              icursos: xicursos,
              someHTML: [],
              someHTML2: [],
            });
          } else {
            this.setState({
              icursos: [],
            });
            if (res.data.variable3.length > 0) {
              this.setState({
                mensajealerta: true,
                tituloM: "Menu Asignar Notas.",
                cuerpoM: res.data.variable3,
              });
            }
          }
        });
    } else {
      this.setState({
        idprofesor: "",
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM: "Debe Seleccionar un Año Escolar, gracias.",
      });
    }
  };

  cargarFechas() {
    let data = new FormData();
    data.append("clave", this.props.usuario1.password);
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/fecha.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xmes = res.data.variable1;
        let xanio = res.data.variable2;
        let xdia = res.data.variable3;
        this.setState({
          dia: xdia,
          mes: xmes,
          anio: xanio,
        });
      }
    });
  }

  consulta = () => {
    if (this.state.cedulaHijo.length > 0) {
      let data = new FormData();
      data.append("clave", this.props.usuario1.password);
      data.append("cedulaHijo", this.state.cedulaHijo);
      data.append("anoEscolar", moment().format("YYYY"));
      data.append("tipoR", this.state.tipoR);
      data.append("fecha1", this.state.fecha1);
      data.append("fecha2", this.state.fecha2);
      data.append("boton", "consultaAcudiente");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          //this.getDates(this.state.fecha1, this.state.fecha2)
          let xsomeHTML = res.data.variable2;
          this.setState({
            //anoEscolar: res.data.variable2,
            idcurso: res.data.variable3,
            idfechaPlani: res.data.variable4,
            idprofesor: res.data.variable5,
            someHTML: xsomeHTML,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Reporte Planificacion.",
        cuerpoM: "Debe Seleccionar un Alumno, gracias.",
      });
    }
  };

  consultaI = (e) => {
    //if (e.length > 0) {
    let data = new FormData();
    data.append("fecha1", e);
    data.append("anoEscolar", moment().format("YYYY"));
    data.append("idgrado", this.state.idcurso);
    data.append("idfechaPlanificacion", this.state.idfechaPlani);
    data.append("idprofesor", this.state.idprofesor);
    data.append("tipoR", this.state.tipoR);

    data.append("boton", "consultaAI");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        let xsomeHTML = res.data.variable2;
        this.setState({
          mostrarOn: true,
          pasoOn: true,
          someHTML2: xsomeHTML,
          fechaImprimir: e,
        });
      }
    });
    //}
  };

  imprimirP = () => {
    //if (e.length > 0) {
    let data = new FormData();
    data.append("fecha1", this.state.fechaImprimir);
    data.append("anoEscolar", moment().format("YYYY"));
    data.append("idgrado", this.state.idcurso);
    data.append("idfechaPlanificacion", this.state.idfechaPlani);
    data.append("idprofesor", this.state.idprofesor);
    data.append("tipoR", this.state.tipoR);

    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/fpdf/reportes/reportePlanificacion.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  buscarProfesor = () => {
    let form = new FormData();
    form.append("boton", "buscarPersonal2");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/nomina2.php";
    axios.post(xurl, form, config).then((res) => {
      let xiprofesor = res.data.variable1;
      this.setState({
        iprofesor: xiprofesor,
      });
    });
  };

  buscarAlumno = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarAlumnos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumno = res.data.variable2;
      this.setState({
        icedulaH: xialumno,
      });
    });
  };

  getDates = (startDate, stopDate) => {
    let xdateArray = new Array();
    let currentDate = moment(startDate);
    //while (currentDate <= stopDate) {
    while (
      currentDate.isBetween(
        moment(stopDate).add(-1, "days"),
        moment(stopDate).add(1, "days")
      )
    ) {
      xdateArray.push(currentDate);
      currentDate = moment(currentDate).add(1, "days");
    }
    this.setState({
      dateArray: xdateArray,
    });
  };

  habilitarAgenda = () => {
    this.setState({loading:true})
    let form = new FormData();
    form.append("cedulaAlumno", this.state.cedulaHijo);
    //form.append("trimestre", this.state.trimestre);
    form.append("boton", "buscarAB");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/habilitarAgenda.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        this.consulta();
        this.setState({loading:false})

      } else {
        this.setState({
          loading:false,
          mensajealerta: true,
          tituloM: "Menu Agenda",
          cuerpoM: "Estamos para servirle, Por Favor Comuniquese con Administración gracias...",
        });
      }
    });
  };


  componentDidMount() {
    this.buscarAlumno();
    //this.buscarProfesor();
    this.buscarAnioEscolar();
    this.cargarFechas();

  }

  ax = () => {};
  render() {
    //console.log(this.state.fecha1)
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CONSULTA DE AGENDA. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          {this.state.mostrarOn === true && (
            <Modal
              open={this.state.mostrarOn}
              someHTML2={this.state.someHTML2}
              imprimir={this.imprimirP}
              onClose={() => this.setState({ mostrarOn: false, pasoOn: false })}
            />
          )}
          {this.state.pasoOn === false && (
            <div className="historia-contenidoant">
              <div className="form-groupev col-12">
                <div className="row">
                  <div className="cont-personal col-12">
                    <div className="contenedorcedula1f col-3">
                      <select
                        id="cedulaHijo"
                        name="cedulaHijo"
                        value={this.state.cedulaHijo}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Seleccione el Alumno --</option>
                        {this.state.icedulaH.map((item, i) => {
                          return (
                            <option key={i} value={item.variable1}>
                              {item.variable2}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="contenedorcedula1f col-2">
                      <select
                        id="tipoR"
                        name="tipoR"
                        value={this.state.tipoR}
                        onChange={this.onChange4.bind(this)}
                      >
                        <option>-- Tipo Consulta --</option>
                        <option value="1">Mensual</option>
                        <option value="2">Por Fechas</option>
                        <option value="3">Diario</option>
                      </select>
                    </div>
                    {/* <div className="contenedorcedula1f col-3"> */}

                    {this.state.tipoR === "1" ? (
                      <div className="contenedorcedula1f col-3">
                        <select
                          id="fecha1"
                          name="fecha1"
                          value={this.state.fecha1}
                          onChange={this.onChange3.bind(this)}
                        >
                          <option>-- Seleccione el Mes --</option>
                          {this.state.mes.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.tipoR === "2" || this.state.tipoR === "3" ? (
                      <div className="contenedorcedula1f col-3">
                        <input
                          className="form-control"
                          type="date"
                          name="fecha1"
                          id="fecha1"
                          autoComplete="off"
                          onChange={this.onChange3.bind(this)}
                          value={this.state.fecha1.toUpperCase()}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.tipoR === "2" ? (
                      <div className="contenedorcedula1f col-3">
                        <input
                          className="form-control"
                          type="date"
                          name="fecha2"
                          id="fecha2"
                          autoComplete="off"
                          onChange={this.onChange3.bind(this)}
                          value={this.state.fecha2.toUpperCase()}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="contenedorcedula1f col-4">
                      <div className="acciones">
                        <button
                          className={
                            !this.state.botonguardarOn ? "boton" : "botonOf"
                          }
                          onClick={this.habilitarAgenda.bind(this)}
                          //onClick={this.consulta.bind(this)}
                        >
                          Consultar
                        </button>
                        <Link to="/">
                          <button className="boton">Salir</button>
                        </Link>
                        <button
                          className="boton"
                          onClick={this.limpiar.bind(this)}
                        >
                          Limpiar
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.pasoOn === false && (
            <div className="container col-12">
              <div className="titulo-cxc ">
                <p>CONSULTA DE AGENDA POR FECHA. </p>
              </div>

              {/* {this.state.mostrarOn === true && (
              <Modal
                open={this.state.mostrarOn}
                someHTML2={this.state.someHTML2}
                imprimir={this.imprimirP}
                onClose={() =>
                  this.setState({ mostrarOn: false, pasoOn: false })
                }
              />
            )} */}

              <div className="listadopcr col-12">
                <table className="tabla2pcr">
                  <tbody className="cuerpo">
                    {/* {this.state.dateArray.map((item, i) => { */}
                    {this.state.someHTML.map((item, i) => {
                      //console.log(this.state.dateArray);

                      return (
                        <tr className={i % 2 === 0 ? "oddr" : ""} key={i}>
                          {item.variable1 !== "" ? (
                            <td
                              className="itemtablaR"
                              key={i}
                              onClick={this.consultaI.bind(
                                this,
                                item.variable1
                              )}
                            >
                              {item.variable1}
                            </td>
                          ) : (
                            ""
                          )}
                          {item.variable2 !== "" ? (
                            <td
                              className="itemtablaR"
                              key={i}
                              onClick={this.consultaI.bind(
                                this,
                                item.variable2
                              )}
                            >
                              {item.variable2}
                            </td>
                          ) : (
                            ""
                          )}
                          {item.variable3 !== "" ? (
                            <td
                              className="itemtablaR"
                              key={i}
                              onClick={this.consultaI.bind(
                                this,
                                item.variable3
                              )}
                            >
                              {item.variable3}
                            </td>
                          ) : (
                            ""
                          )}
                          {item.variable4 !== "" ? (
                            <td
                              className="itemtablaR"
                              key={i}
                              onClick={this.consultaI.bind(
                                this,
                                item.variable4
                              )}
                            >
                              {item.variable4}
                            </td>
                          ) : (
                            ""
                          )}
                          {item.variable5 !== "" ? (
                            <td
                              className="itemtablaR"
                              key={i}
                              onClick={this.consultaI.bind(
                                this,
                                item.variable5
                              )}
                            >
                              {item.variable5}
                            </td>
                          ) : (
                            ""
                          )}
                          {item.variable6 !== "" ? (
                            <td
                              className="itemtablaR"
                              key={i}
                              onClick={this.consultaI.bind(
                                this,
                                item.variable6
                              )}
                            >
                              {item.variable6}
                            </td>
                          ) : (
                            ""
                          )}
                          {item.variable7 !== "" ? (
                            <td
                              className="itemtablaR"
                              key={i}
                              onClick={this.consultaI.bind(
                                this,
                                item.variable7
                              )}
                            >
                              {item.variable7}
                            </td>
                          ) : (
                            ""
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(ReportePla);
