import React, { Component, useState } from "react";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import moment from "moment";

//componentes
import "./tercerTrimestre.css";
import Alert from "../../modal/alert";
import AdjuntaTareas from "../adjuntarTareas/adjuntarTareas";
import TareasRecibidas from "../curriculumRecibidos/curriculumRecibidos";
import { saveCliente, saveToken } from "../../accion/accion";
import { outCliente1, outToken } from "../../accion/accion";
//componentes

const TercerTrimestre = (props) => {
  const [recibir2On, setRecibir2On] = useState(true);
  const [trimestre, setTrimestre] = useState(3);
  if (recibir2On === false) {
    return <Redirect to="/" />;
  }
  return (
    <div className="formulario">
      <div className="btnx">
        <TareasRecibidas
          open={recibir2On}
          idgrado={props.usuario1.nivel}
          tipoConsulta={props.usuario1.anioE}
          trimestre={trimestre}
          onClose={() => setRecibir2On(false)}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

const mapDispatchToProps = {
  outCliente1,
  outToken,
  saveCliente,
  saveToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(TercerTrimestre);
