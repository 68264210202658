import React from "react";
import { Row, Col, Container } from "reactstrap";
import FeatherIcon from "feather-icons-react";
import styled from "styled-components";
import { CrearMensajesChat } from "../../API/Core";
import moment from "moment";
import socket from "../socketio/socketio";

export default function FuncionName(props) {
  const [mensaje, setMensaje] = React.useState("");

  const EnviarMensaje = async () => {
    //console.log("ssss"+props.selected)
    if (mensaje.length > 0) {
      let idprofesor = props.selected.profesor;
      let cedulaAlumno = props.selected.alumno;
      let idmateria = props.selected.materia;
      let mensaje_ = mensaje;
      let response = await CrearMensajesChat(
        idprofesor,
        cedulaAlumno,
        idmateria,
        mensaje_
      );

      if (response.data.variable1) {
        mensaje_ = {
          variable: "Representante",
          variable2: mensaje,
          variable3: moment(),
          variable4: "0",
          variable5: String(props.selected.id),
        };
        setMensaje("");
        props.pushMessage(mensaje_);
        socket.emit("Representante", {
          profesor: idprofesor,
          alumno: cedulaAlumno,
          materia: idmateria,
        });
      }
    }
  };

  return (
    <Row>
      <Col style={{ paddingRight: "0" }} xs="10">
        <MessageField
          className="form-control"
          id="mensaje"
          name="mensaje"
          autoComplete="off"
          value={mensaje}
          onChange={(e) => setMensaje(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              EnviarMensaje();
            }
          }}
        />
      </Col>
      <Col style={{ paddingLeft: "0" }} className="text-center">
        <Send onClick={EnviarMensaje}>
          <FeatherIcon icon="send" stroke="#fff" size="18" />{" "}
        </Send>
      </Col>
    </Row>
  );
}

const MessageField = styled.textarea`
  height: 80px;
  resize: none;
`;

const Send = styled.button`
  border-radius: 3px;
  width: 100%;
  height: 100%;
  border: solid 1px #eee;
  background-color: #028d7b;
  text-align: center;
  transition: all 0.3s;
  :hover {
    opacity: 0.8;
  }
  svg {
    width: 20px;
    stroke: #ffff;
  }
`;
