import React from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { BuscarMensajesChat, MarcarSalaLeido } from "../../API/Core";
import moment from "moment";
import EnviarMensaje from "./EnviarMensaje";

export default function Mensajes(props) {
  const [mensajes, setMensajes] = React.useState([]);

  const initData = async () => {
    let response = await BuscarMensajesChat(props.selected.id);
    await MarcarSalaLeido(props.selected.id);
    setMensajes(response.data.variable2);
    var objDiv = document.getElementById("container-mensajes");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  const pushMessage = (mensaje) => {
    let mensajes_ = Array.from(mensajes);
    mensajes_.push(mensaje);
    if (mensajes.length === 0) props.updatListChats();
    setMensajes(mensajes_);

    var objDiv = document.getElementById("container-mensajes");
    objDiv.scrollTop = objDiv.scrollHeight;
  };

  React.useEffect(() => {
    //console.log("aqui " + props.selected);
    if (props.selected !== null) {
      if (props.selected.id !== null) {
        initData();
      } else {
        setMensajes([]);
      }
    }
  }, [props.selected]);

  return (
    <Container>
      <ContainerMensaje id="container-mensajes">
        {mensajes.map((mensaje, key) => (
          <Row key={key} className="mb-2">
            <Col>
              <MessageCard remitente={mensaje.variable} key={key}>
                <Remitente remitente={mensaje.variable}>
                  {mensaje.variable === "Representante" ? "Yo" : "Profesor"} :
                  {/* props.selected.alumno_name */}
                </Remitente>
                <Message>{mensaje.variable2}</Message>
                <Message>
                  <Date>
                    {moment(mensaje.variable3).diff(moment(), "days") === 0
                      ? moment(mensaje.variable3).format("hh:MM A")
                      : moment(mensaje.variable3).format("DD/MM/YYYY")}
                  </Date>
                </Message>
              </MessageCard>
            </Col>
          </Row>
        ))}
        {props.selected === null && (
          <WithoutMessages>No has seleccionado ningún chat.</WithoutMessages>
        )}
      </ContainerMensaje>
      {props.selected !== null && (
        <EnviarMensaje pushMessage={pushMessage} selected={props.selected} />
      )}
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  max-height: 100%;
`;

const Date = styled.small`
  top: -2px;
  position: relative;
  float: right;
  width: 100%;
  text-align: right;
  color: #777;
  font-weight: 300;
`;

const ContainerMensaje = styled.div`
  padding: 10px 15px;
  height: 85%;
  overflow-y: auto;
`;
const WithoutMessages = styled.div`
  text-align: center;
  padding-top: 200px;
  color: #bbb;
  font-weight: 300;
`;

const MessageCard = styled.div`
  min-width: 140px;
  position: relative;
  display: block;
  float: ${(props) => (props.remitente === "Profesor" ? "right" : "left")};
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #eee;
  width: auto;
  max-width: 80%;
  font-size: 14px;
`;

const Remitente = styled.p`
  width: auto;
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 600;
  color: ${(props) => (props.remitente === "Profesor" ? "#2b8345" : "#004cff")};
`;

const Message = styled.div`
  font-size: 14px;
  max-width: 100%;
  width: auto;
`;
