import {createStore, combineReducers, compose } from 'redux';
import persistente from 'redux-localstorage';
const defaultState = []

function clienteReducer (state = defaultState, action) {
    switch(action.type){
        
            case 'SET_CLIENTE':
            const {password, usuario, nivel, anioE, cedulaU} = action;
            return { 
                password, 
                usuario,
                nivel,
                anioE,
                cedulaU
            }

            case 'OUT_CLIENTE':
               return ''

        default:
            return state;
    }
}

function tokenReducer (state='',action){
    switch(action.type){
        
        case 'SET_TOKEN':
        const {token} = action;
        return { 
            token
        }
    
        case 'OUT_TOKEN':
            return ''

        default:
            return state;
    }
}

function botonMensajeReducer (state='',action){
    switch(action.type){
        
        case 'SET_BOTONMENSAJE':
        const {botonMensaje} = action;
        return { 
            botonMensaje
        }
    
        case 'OUT_BOTONMENSAJE':
            return ''

        default:
            return state;
    }
}


let rootReducer = combineReducers({
    usuario1: clienteReducer,
    token: tokenReducer,
    botonMen: botonMensajeReducer,
});

let menupersistente = compose(persistente());

export default createStore(rootReducer,{},menupersistente);