import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

//componentes
import "./Grado.css";
import Alert from "../../modal/alert";

class GradoAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonOn: true,
      scurso: null,
      mensajealerta: false,
      codigo1: "",
      codigo2: "",
      descripcion: "",
      salonCurso: "",
      guardarOn: false,
      icursos: [],
      igrados: [],
      iseccion: [],
      cantAlumnos: "",
      nombreGrado: "",
      modificarOn: false,
    };
  }

  limpiar = () => {
    this.setState({
      botonOn: true,
      mensajealerta: false,
      codigo1: "",
      codigo2: "",
      descripcion: "",
      salonCurso: "",
      guardarOn: false,
      cantAlumnos: "",
      nombreGrado: "",
      modificarOn: false,
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }


  guardar = () => {
    if (this.state.nombreGrado.length > 0) {
      let data = new FormData();
      data.append("nombreGrado", this.state.nombreGrado);
      data.append("boton", "guardar");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/curriculum.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.limpiar();
            this.buscarGrado();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Grados",
              cuerpoM: "Se Agrego correctamente",
            });
          } else {
            //console.log(res.data.variable2);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Grados",
              cuerpoM: "No se pudo agregar, verifique Gracias...",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Maestro de Grados",
        cuerpoM:
          "Debe haber una descripcion como minimo para poder Agregarlo, verifique Gracias...",
      });
    }
  };

  consultar = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  eliminar = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idgrados", e);
      data.append("boton", "eliminar");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/curriculum.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarGrado();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Grados",
              cuerpoM: "Se Elimino correctamente el Grado",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Grados",
              cuerpoM: "No se pudo Eliminar el Grado, verifique Gracias...",
            });
          }
        });
    }
  };

  

  buscarGrado = () => {
    let data = new FormData();
    data.append("boton", "consultaGrado");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/curriculum.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            igrados: res.data.variable2,
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarGrado();
  }

  render() {
    //console.log(this.state.oferta)
    return (
      <div className="cont-casos row">
        <div className="cont-casos-formulario col-12">
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="row">
            <div className="cont-titulocasos col-12">
              <span className="titulocasos">Maestro de Grados Primaria</span>
            </div>

            <div className="cont-datoscasos col-12">
              <div className="row">
                <div className="contenedorcedula1x col-12">
                  <input
                    className="form-control"
                    type="text"
                    name="nombreGrado"
                    id="nombreGrado"
                    autoComplete="off"
                    placeholder="Nombre del Grado"
                    onChange={this.onChange.bind(this)}
                    value={this.state.nombreGrado.toUpperCase()}
                  />
                </div>
              </div>
              <div className="botonescasos row">
                <div className="accionescasos col-12">
                  <button
                    className={
                      this.state.guardarOn ? "botoncasosOff" : "botoncasos"
                    }
                    disabled={this.state.guardarOn}
                    onClick={this.guardar.bind(this)}
                  >
                    Agregar
                  </button>
                  <button
                    className="botoncasos"
                    onClick={this.limpiar.bind(this)}
                  >
                    Limpiar
                  </button>
                  <Link to="/">
                    <button className="botoncasos">Salir</button>
                  </Link>
                </div>
              </div>
              <div className="cont-listmedi row">
                <div className="listmedi col-12">
                  <table className="tablame" border="1">
                  <thead>
                      <tr>
                        <th className="titucxc1">CODIGO</th>
                        <th className="titucxc2">DESCRIPCION</th>
                        <th className="titucxc3">
                          <span className="cerrarme3"></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="evocuerpo1">
                      {this.state.igrados.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td
                              width="20"
                             
                            >
                              {item.variable1}
                            </td>
                            <td
                              width="20"
                              
                            >
                              {item.variable2}
                            </td>
                            <td width="20">
                              <span
                                className="cerrarme1"
                                onClick={this.eliminar.bind(
                                  this,
                                  item.variable1
                                )}
                              ></span>{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GradoAction;
