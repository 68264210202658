import React from "react";
import { buscarChatEnviar } from "../../API/Core";
import ChatCard from "./ChatCard";
import { connect } from "react-redux";
import socket from "../socketio/socketio"
import { saveCliente, saveToken, saveBotonMensaje} from '../../accion/accion'


function ListaChats(props) {
  const [chats, setChats] = React.useState([]);
  
  const initData = async () => { 
    let response = await buscarChatEnviar(props.id_alumno);
    setChats(response.data.variable2);
  };

  const MarcarComoLeido = (idsala) => {
    let chats_ = Array.from(chats);
    let find = chats_.find((e) => e.variable === String(idsala));
    let index = chats_.indexOf(find);
    chats_[index].variable2 = "1";
    props.saveBotonMensaje(false);
    setChats(chats_);
  };

  React.useEffect(() => {
    //actualizar chat cuando envien mensajes
    socket.on(props.id_alumno, (data) => {
      props.saveBotonMensaje(true);
      initData();      
    });
    return () => {
      socket.off();
    };
  }, [props.refreshChats]);





  React.useEffect(() => {
    initData();
  }, [props.refreshChats]);

  return (
    
    <div>
      {chats.map((el, key) => (
        <ChatCard
          selected={
            props.selected !== null && el.variable === props.selected.id
          }
          onClick={props.onClick}
          MarcarComoLeido={MarcarComoLeido}
          chat={el}
          key={key}
          index={key}
        />
      ))}
      {chats.length === 0 && <p>No hay conversaciones.</p>}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
    botonMen: state.botonMen,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje

}
export default connect(mapStateToProps,mapDispatchToProps)(ListaChats);