import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

//componentes
import "./permiso.css";
import Alert from "../../modal/alert";

class PermisosAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonOn: true,
      scurso: null,
      mensajealerta: false,
      codigo1: "",
      codigo2: "",
      descripcion: "",
      salonCurso: "",
      guardarOn: false,
      icursos: [],
      ipermisos: [],
      igrados: [],
      cantAlumnos: "",
      correo: "",
      idgrado: "",
      tipoConsulta: "",
      trimestre: "",
      modificarOn: false,
      alumnos: "",
      coordina: "",
      docente: "",
      primerT:'',
      segundoT:'',
      tercerT:'',
    };
  }

  limpiar = () => {
    this.setState({
      botonOn: true,
      mensajealerta: false,
      codigo1: "",
      codigo2: "",
      descripcion: "",
      salonCurso: "",
      guardarOn: false,
      cantAlumnos: "",
      correo: "",
      idgrado: "",
      tipoConsulta: "",
      trimestre: "",
      modificarOn: false,
      alumnos: "",
      coordina: "",
      docente: "",
      primerT:'',
      segundoT:'',
      tercerT:'',
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2(e) {
    if (e.target.checked) {
      this.setState({
        [e.target.name]: true,
      });
    } else {
      this.setState({
        [e.target.name]: false,
      });
    }
  }

  guardar = () => {
    if (
      this.state.idgrado.length > 0 &
      this.state.correo.length > 0
    ) {
      let data = new FormData();
      data.append("idgrado", this.state.idgrado);
      data.append("coordinacion", this.state.coordina);
      data.append("docente", this.state.docente);
      data.append("alumno", this.state.alumnos);
      data.append("primerT", this.state.primerT);
      data.append("segundoT", this.state.segundoT);
      data.append("tercerT", this.state.tercerT);
      data.append("correo", this.state.correo);
      data.append("boton", "guardarPermiso");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/curriculum.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarPermisos();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Permisos",
              cuerpoM: "Se Agrego correctamente",
            });
          } else {
            //console.log(res.data.variable2);
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Permisos",
              cuerpoM: "No se pudo agregar o ya existe, verifique Gracias...",
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Maestro de Permisos",
        cuerpoM:
          "Debe seleccionar todos los datos y colocar un correo, verifique Gracias...",
      });
    }
  };

  eliminar = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idpermiso", e);
      data.append("boton", "eliminarPermisos");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/curriculum.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            this.buscarPermisos();
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Permisos",
              cuerpoM: "Se Elimino correctamente el Permiso",
            });
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Maestro de Permiso",
              cuerpoM: "No se pudo Eliminar el Permiso, verifique Gracias...",
            });
          }
        });
    }
  };

  buscarPermisos = () => {
    let data = new FormData();
    data.append("boton", "consultaPermisos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/curriculum.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            ipermisos: res.data.variable2,
          });
        }
      });
  };

  buscarGrado = () => {
    let data = new FormData();
    data.append("boton", "consultaGrado");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/curriculum.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            igrados: res.data.variable2,
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.buscarPermisos();
    this.buscarGrado();
  }

  render() {
    //console.log(this.state.oferta)
    return (
      <div className="cont-casos row">
        <div className="cont-casos-formulario col-12">
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          <div className="row">
            <div className="cont-titulocasos col-12">
              <span className="titulocasos">Aplicar Permisos a Usuarios</span>
            </div>

            <div className="cont-datoscasos col-12">
              <div className="botonescasos row">
                <div className="contenedorcedula1f col-3">
                  <select
                    id="idgrado"
                    name="idgrado"
                    value={this.state.idgrado}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Seleccione el Grado --</option>
                    {this.state.igrados.map((item, i) => {
                      return (
                        <option key={i} value={item.variable1}>
                          {item.variable2}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="contenedorcedula1f col-2">

                  <div className="cont-opcion2">
                    <label className="opcion">
                      Coordinación.
                      <input
                        className="form-control"
                        type="checkbox"
                        name="coordina"
                        id="coordina"
                        value="1"
                        checked={this.state.coordina}
                        onChange={this.onChange2.bind(this)}
                      />
                    </label>
                  </div>

                  <div className="cont-opcion2">
                    <label className="opcion">
                      Docente.
                      <input
                        className="form-control"
                        type="checkbox"
                        name="docente"
                        id="docente"
                        value="2"
                        checked={this.state.docente}
                        onChange={this.onChange2.bind(this)}
                      />
                    </label>
                  </div>

                  <div className="cont-opcion2">
                    <label className="opcion">
                      Alumnos.
                      <input
                        className="form-control"
                        type="checkbox"
                        name="alumnos"
                        id="alumnos"
                        value="3"
                        checked={this.state.alumnos}
                        onChange={this.onChange2.bind(this)}
                      />
                    </label>
                  </div>
                </div>
                <div className="contenedorcedula1f col-2">
                <div className="cont-opcion2">
                    <label className="opcion">
                    1er. Trimestre.
                      <input
                        className="form-control"
                        type="checkbox"
                        name="primerT"
                        id="primerT"
                        value="1"
                        checked={this.state.primerT}
                        onChange={this.onChange2.bind(this)}
                      />
                    </label>
                  </div>

                  <div className="cont-opcion2">
                    <label className="opcion">
                    2do. Trimestre.
                      <input
                        className="form-control"
                        type="checkbox"
                        name="segundoT"
                        id="segundoT"
                        value="2"
                        checked={this.state.segundoT}
                        onChange={this.onChange2.bind(this)}
                      />
                    </label>
                  </div>

                  <div className="cont-opcion2">
                    <label className="opcion">
                    3er. Trimestre.
                      <input
                        className="form-control"
                        type="checkbox"
                        name="tercerT"
                        id="tercerT"
                        value="3"
                        checked={this.state.tercerT}
                        onChange={this.onChange2.bind(this)}
                      />
                    </label>
                  </div>
                </div>
                <div className="contenedorcedula1x col-4">
                  <input
                    className="form-control"
                    type="text"
                    name="correo"
                    id="correo"
                    autoComplete="off"
                    placeholder="Correo"
                    onChange={this.onChange.bind(this)}
                    value={this.state.correo.toUpperCase()}
                  />
                </div>
              </div>
              <div className="botonescasos row">
                <div className="accionescasos col-12">
                  <button
                    className={
                      this.state.guardarOn ? "botoncasosOff" : "botoncasos"
                    }
                    disabled={this.state.guardarOn}
                    onClick={this.guardar.bind(this)}
                  >
                    Agregar
                  </button>
                  <button
                    className="botoncasos"
                    onClick={this.limpiar.bind(this)}
                  >
                    Limpiar
                  </button>
                  <Link to="/">
                    <button className="botoncasos">Salir</button>
                  </Link>
                </div>
              </div>

              <div className="cont-listmedi row">
                <div className="listmedi col-12">
                  <table className="tablame" border="1">
                    <thead>
                      <tr>
                        <th className="titucxc1">CORREO</th>
                        <th className="titucxc2">GRADO</th>
                        <th className="titucxc3">TIPO CONSULTA</th>
                        <th className="titucxc3">TRIMESTRE</th>
                        <th className="titucxc3">
                          <span className="cerrarme3"></span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="evocuerpo1">
                      {this.state.ipermisos.map((item, i) => {
                        return (
                          <tr
                            className={i % 2 === 0 ? "odd" : ""}
                            key={item.variable1}
                            id="tabla"
                          >
                            <td width="20">{item.variable2}</td>
                            <td width="20">{item.variable3}</td>
                            <td width="20">{item.variable4}</td>
                            <td width="20">{item.variable5}</td>
                            <td width="20">
                              <span
                                className="cerrarme1"
                                onClick={this.eliminar.bind(
                                  this,
                                  item.variable1
                                )}
                              ></span>{" "}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default PermisosAction;
