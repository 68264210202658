import React, { Component } from 'react';

//componentes
import Permisos from './permiso'

class Grado extends Component {

    render(){
        return(
            <div>
                <Permisos />
            </div>
        );
    }

}
export default Grado;