import { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

//componentes
import Alert from "../../Mensajes/alert";
import { saveCliente, saveToken } from "../../accion/accion";
import "./login.css";

function LoginAction(props) {
  const [user, setUser] = useState({});
  const [redireccionar, setRedireccionar] = useState(false);
  const clientId =
    "662308602201-mlphv922kd7ltmrv944f2p3pab2gh98s.apps.googleusercontent.com";

  if (redireccionar === true) {
    // let username = "HPPEROG@GMAIL.COM";
    // let clav = "";
    // let nivel = "";
    // let anioE = "";
    // let cedulaU = "";
    // props.saveCliente(username, clav, nivel, anioE, cedulaU);

    return <Redirect to="/" />;
  }

  return (
    <div className="formulario">
      <div className="btnx">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            //console.log(credentialResponse.credential);
            var decoded = jwt_decode(credentialResponse.credential);
            //console.log(decoded);
            //console.log(decoded.email);
            setUser(decoded);
            let username = decoded.email.toUpperCase();
            let clav = "";
            let nivel = "";
            let anioE = "";
            let cedulaU = "";
            props.saveCliente(username, clav, nivel, anioE, cedulaU);
            //document.getElementsByClassName("btnx").hidden = true;
            setRedireccionar(true);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
        ;
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginAction);
