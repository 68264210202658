import React, { Component } from 'react';

//Component
import PDFAction from './pdf'

class Login extends Component {

  render() {

    return (
      <div>
          <PDFAction />
      </div>
    );
  }
}

export default Login; 