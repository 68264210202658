import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { saveCliente, saveToken, saveBotonMensaje } from "../../accion/accion";
import RingLoader from "react-spinners/ScaleLoader";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardSubtitle,
  CardImg,
  CardTitle,
  CardText,
  CardColumns,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import renderHTML from "react-render-html";
const ModalExample = (props) => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paso, setPaso] = useState(false);
  const [idactividad, setIdactividad] = React.useState([]);

  const toggle = () => setModal(!modal);

  const InitData = () => {
    let data = new FormData();
    data.append("idgrado", props.idgrado);
    data.append("tipoConsulta", props.tipoConsulta);
    data.append("trimestre", props.trimestre);
    data.append("boton", "buscarCurriculumEnviados");

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/curriculum.php",
      data: data,
    }).then((res) => {
      setIdactividad(res.data.variable2);
    });
  };
  const consultarImagen = (e) => {
    console.log("Imagen: " + e);
    let username = props.usuario1.usuario;
    let clav = "";
    let nivel = props.usuario1.nivel;
    let anioE = props.usuario1.anioE;
    let cedulaU = e;
    props.saveCliente(username, clav, nivel, anioE, cedulaU);
    setPaso(true);
  };

  const eliarchivo = (e, i) => {
    setLoading(true);
    let data = new FormData();
    data.append("idarchivos", e);
    data.append("rutaArchivos", i);
    data.append("boton", "eliminarAdjuntosCurriculum");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/curriculum.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        setLoading(false);
        if (res.data.variable1) {
          InitData();
        } else {
        }
        //console.log(res);
      });
  };

  React.useEffect(() => {
    InitData();
  }, []);

  return (
    <div>
      {paso === true && <Redirect to="/pdf" />}
      <Modal
        isOpen={props.open}
        toggle={props.onClose}
        scrollable={true}
        className={className}
        size=""
      >
        {loading && (
          <div className="cont-spinner row">
            <div className="spinner col-6">
              <RingLoader
                clasName="spinner"
                sizeUnit={"px"}
                size={160}
                width={19}
                radius={20}
                height={160}
                color={"#48e120"}
                loading={loading}
              />
            </div>
          </div>
        )}
        <ModalHeader toggle={props.onClose}>Archivos Enviados</ModalHeader>
        <ModalBody
          body
          inverse
          style={{ backgroundColor: "#bbbbbb", borderColor: "#333" }}
        >
          {idactividad.map((item, i) => {
            return (
              <Card className="mb-4">
                <CardHeader>
                  <Card className="mb-1">
                    <CardHeader>
                      <Titulo1 className="row">
                        Nombre Archivo: {item.variable2}
                      </Titulo1>
                      <Titulo2 className="row">
                        Fecha Enviado: {item.variable4}
                      </Titulo2>
                    </CardHeader>
                    <CardBody>
                      
                        <Boton1
                          onClick={() => {
                            consultarImagen(item.variable3);
                          }}
                        >
                          
                          <img
                            src={item.variable3}
                            height="55px"
                            width="55px"
                          />
                          
                        </Boton1>
                      
                      {/* <a
                        href={item.variable3}
                        target="popup"
                        onClick="window.open(this.href, this.target, 'width=350,height=420,toolbar=no,directories=no,status=no,menubar=no'); return false;"
                      >
                        {" "}
                        <img
                          src={item.variable3}
                          height="55px"
                          width="55px"
                        />{" "}
                      </a> */}
                    </CardBody>
                    {/* <CardFooter>
                        <Boton1
                          onClick={() => {
                            eliarchivo(item.variable1, item.variable5);
                          }}
                        >
                          Eliminar
                        </Boton1>
                    </CardFooter> */}
                  </Card>
                </CardHeader>
              </Card>
            );
          })}
        </ModalBody>

        <ModalFooter>
          {/* <Button color="primary" onClick={props.onClose}>
            Cerrar
          </Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
    botonMen: state.botonMen,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalExample);

const Titulo1 = styled.div`
  font-size: 14px;
`;
const Titulo2 = styled.div`
  font-size: 12px;
`;
const Titulo3 = styled.div`
  font-size: 10px;
`;
const Titulo4 = styled.div`
  font-size: 10px;
`;
const Titulo5 = styled.div`
  font-size: 10px;
`;
const Titulo6 = styled.div`
  font-size: 10px;
`;
const MensajeTarea = styled.div`
  font-size: 12px;
`;
const Boton1 = styled.button`
  color: #fff;
  font-size: 12px;
  background-color: #2bbbad;
  border: none;
  text-align: right;
  :hover {
    background-color: red;
  }
`;
