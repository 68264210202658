import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { BuscarAlumno } from "../../API/Core";
import { BuscarCantidadAlumno } from "../../API/Core";
import FilterField from "./FilterField";
import { Row, Col } from "reactstrap";
import ListaChats from "./ListaChats";
import Mensajes from "./Mensajes";
//componentes
function Chat(props) {
  const [cedulaAlumno, setCedulaAlumno] = React.useState(null);
  const [chatSelected, setChatSelected] = React.useState(null);
  const [refreshChats, setRefreshChats] = React.useState(0);
  const [cantAlumnos, setCantAlumnos] = React.useState([]);

  const InitData = async () => {
    try {
      let response = await BuscarCantidadAlumno(props.usuario1.password);
      setCantAlumnos(response.data.variable1);
    } catch (err) {
      console.log(err);
    }
  };

  const InitData2 = async () => {
    try {
      let response = await BuscarAlumno(props.usuario1.password);
      setCedulaAlumno(response.data.variable1);
    } catch (err) {
      console.log(err);
    }
  };

  const Seleccionar_Materia = (materia) => {
    if (materia !== null) {
      setChatSelected({
        id: null,
        profesor: materia.cedula,
        alumno: String(cedulaAlumno),
        materia: materia.materia,
        nombre_materia: materia.nombre,
      });
      //console.log(materia)
    }
  };

  const Seleccionar_Chat = (data) => {
    setChatSelected(data);
  };

  React.useEffect(() => {
    InitData();
  }, []);

  return (
    <div>
      {cedulaAlumno == null && (
        <div className="contenedor-titulo row">
          <div className="titulo-pago col-12">
            <p>DEBE SELECCIONAR SU ACUDIENTE. </p>
          </div>
          <div className="cont-personal col-12">
            <div className="contenedorcedula1f col-3">
              <select
                id="cedulaAlumno"
                name="cedulaAlumno"
                value={cedulaAlumno}
                onChange={(e) => setCedulaAlumno(e.target.value)}
                // onChange={this.onChange.bind(this)}
              >
                <option>-- Seleccione el Alumno --</option>
                {cantAlumnos.map((item, i) => {
                  return (
                    <option key={i} value={item.variable1}>
                      {item.variable2}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      )}
      {cedulaAlumno !== null && (
        <Container>
          <Sidebar>
            <Row className="mt-3">
              <Col>
                {
                  <FilterField
                    id_alumno={cedulaAlumno}
                    onChange={Seleccionar_Materia}
                  />
                }
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <ListaChats
                  refreshChats={refreshChats}
                  selected={chatSelected}
                  id_alumno={cedulaAlumno}
                  onClick={Seleccionar_Chat}
                />
              </Col>
            </Row>
          </Sidebar>
          <MessagesContent>
            <Mensajes
              updatListChats={() => setRefreshChats(refreshChats + 1)}
              selected={chatSelected}
            />
          </MessagesContent>
        </Container>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps)(Chat);

const Container = styled.div`
  height: 80vh;
`;

const Sidebar = styled.aside`
  width: 275px;
  border-right: solid 1px #eee;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  float: left;
`;

const MessagesContent = styled.section`
  width: calc(100% - 275px);
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  float: left;
`;
