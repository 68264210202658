//dependencias
import React, {Component} from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';

//estilos
import './Inicio.css'

//importar componentes

class InicioAction extends Component {
    constructor(props){
        super(props);
        this.state={};
    }
    render(){
        //console.log('paswword - '+this.props.token.token);
        return(
            <div className="contenedorpr">
                {this.props.usuario1.usuario === undefined && 
                <Redirect to='/login' />                    
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        usuario1: state.usuario1,
        token: state.token,
    }
}
export default connect(mapStateToProps,)(InicioAction);