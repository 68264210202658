import React from "react";
import { buscarChatEnviar } from "../../API/Core";
import { connect } from "react-redux";
import socket from "../socketio/socketio";
import { saveCliente, saveToken, saveBotonMensaje } from "../../accion/accion";
import "./pdf.css";
function PDF(props) {
  const [chats, setChats] = React.useState([]);

  // const MarcarComoLeido = (
  //   e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  // ) => {
  //   console.log(e);
  // };

  const MarcarComoLeido2 = () => {
    console.log("paso")
    document.oncontextmenu = (ev) => {
      ev.preventDefault();
      console.log("Prevented to open menu!");
    };
  };

  React.useEffect(() => {
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  //console.log(props.usuario1.cedulaU);
  return (
    <div className="principal1">
      {/* <div className="encima"></div> */}

      <iframe
        id="myIframe"
        name="myIframe"
        className="contenedorFrame"
        width="200%"
        height="100%"
        src={props.usuario1.cedulaU}
        onload={MarcarComoLeido2()}
        onMyLoad={MarcarComoLeido2()}
        //onContextMenu={(e) => MarcarComoLeido2(e)}
        // onload={MarcarComoLeido2()}
        // onMyLoad={MarcarComoLeido2()}
        //onClick={(e) => MarcarComoLeido2(e)}
      ></iframe>
      {/* <object
        //id="myIframe"
        className="contenedorFrame"
        data={props.usuario1.cedulaU}
        //type="application/HTML"
        width="100%"
        height="100%"
        contextMenu={MarcarComoLeido2()}
      ></object> */}
    </div>
    // <div className="principal1">
    //   <div className="xcontenedor">
    //   <div class="bloqueo"></div>
    //     <div className="pdf">
    //       <object
    //         data={props.usuario1.cedulaU}
    //         type="application/PDF"
    //         width="100%"
    //         height="100%"
    //       ></object>
    //     </div>
    //     <div class="bloqueo"></div>

    //   </div>
    // </div>
  );
}

const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
    botonMen: state.botonMen,
  };
};

const mapDispatchToProps = {
  saveCliente,
  saveToken,
  saveBotonMensaje,
};
export default connect(mapStateToProps, mapDispatchToProps)(PDF);
