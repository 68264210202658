import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";

//import './index.css';
import store from "./store/store";
import App from "./components/App";

ReactDOM.render(
  // render(
  <GoogleOAuthProvider clientId="662308602201-mlphv922kd7ltmrv944f2p3pab2gh98s.apps.googleusercontent.com">
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
    ,
  </GoogleOAuthProvider>,

  document.getElementById("root")
);

serviceWorker.unregister();
